!function (e) {
    var i = "nav#ubermenu-main-265-primary-menu-2",
        t = "ul.ubermenu-tab-content-panel.ubermenu-column.ubermenu-column-4-5.ubermenu-submenu.ubermenu-submenu-type-tab-content-panel",
        s = "nav > ul > li > ul > li > ul > li > ul > li > ul > li > ul";
    if (jQuery("#menu-item-595").on("ubermenuopen", function () {
            e(i).addClass("hidden"), e(t).removeClass("hidden")
        }), jQuery("#menu-item-595").on("ubermenuclose", function () {
            e(i).removeClass("hidden"), e(t).addClass("hidden")
        }), jQuery("#menu-item-600").on("ubermenuopen", function () {
            e(i).addClass("hidden"), e(t).addClass("hidden"), e(s).removeClass("hidden"), jQuery("#menu-item-595").on("ubermenuopen", function () {
                e(i).addClass("hidden"), e(t).addClass("hidden")
            })
        }), jQuery("#menu-item-600").on("ubermenuclose", function () {
            e(i).addClass("hidden"), e(t).removeClass("hidden"), e(s).addClass("hidden")
        }), e("#filterToggle").click(function () {
            e(".filter-accordion-header span").toggleClass("opened"), e(".filter-accordion-content").slideToggle("fast")
        }), e(window).resize(function () {
            window.innerWidth >= 961 ? (e(".filter-accordion-content").css("display", "block"), e(".filter-accordion-header span").removeClass("opened")) : window.innerWidth <= 960 && e(".filter-accordion-header span").not("opened") && e(".filter-accordion-content").css("display", "none")
        }), e(document).ready(function () {
            window.location.href.indexOf("/products/") > -1 && e("#menu-item-743").addClass("individual-product-page")
        }), e(function () {
            e("h1, h2, h3, h4, h5, h6, p, span, a").each(function (i, t) {
                e(t).html(function (e, i) {
                    return i.replace(/(®)/g, "<sup>&reg;</sup>")
                })
            })
        }), e(".blog .page-title").text("NEWS").css("visibility", "visible"), e("a > p.woocommerce-result-count").removeAttr("href"), e(".woocommerce-Tabs-panel").contents().filter(function () {
            return 3 === this.nodeType
        }).remove(), e(document).on("click", ".button.button.woof_reset_search_form", function (i) {
            console.log("clicked"), e("body").addClass("clicked"), e(".table-wrap").removeClass("table-display"), e(".ctm_responsive_table.auto-gen").removeClass("table-display"), e(".woocommerce-pagination").removeClass("table-display"), e(".woocommerce-result-count").removeClass("table-display")
        }), e("body").hasClass("woocommerce-page") && !e("body").hasClass("single-product")) {
            e('<div class="choose-attributes"><p>Please choose from the available options below.</p></div>').appendTo(".prod-guide-row"),
            e('select option:contains("Platform Depth")').text("Please Choose"), e('select option:contains("Tread Type")').text("Please Choose"), e('select option:contains("Tread Width")').text("Please Choose"), e("#productGuide").click(function () {
            e(".product-guide-icon").toggleClass("opened"), e("#productGuideContent").slideToggle()
        });
        var a = e(window).width();
        window.innerWidth <= 768 && (e(".product-guide-icon").removeClass("opened"), e("#productGuideContent").slideUp()), e(window).resize(function () {
            window.innerWidth <= 768 && e(window).width() != a && (e(".product-guide-icon").removeClass("opened"), e("#productGuideContent").slideUp())
        }),
            function () {
                e(".table-wrap .prod-blurb").remove(), e(".table-wrap .woocommerce-LoopProduct-link.woocommerce-loop-product__link").remove()
            }()
    }
}(jQuery);